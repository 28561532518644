function pageScroll(sectionName, animate = false) {
  let scrollDistance = 0

  if (sectionName) {
    const section = document.querySelectorAll(".anchor-" + sectionName)
    scrollDistance =
      window.pageYOffset + section[0].getBoundingClientRect().top - 120
  }

  window.scroll({
    top: scrollDistance,
    behavior: animate ? "smooth" : "auto",
  })
}

export { pageScroll }
