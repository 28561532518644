import React, { useContext } from "react"
import { navigate } from "gatsby"

import { InteractionsContext } from "@contexts/interactions"

import { pageScroll } from "@utils/scroller"

function Horizontal() {
  const { actionLoader, actualPathname, saveSection } = useContext(InteractionsContext)

  function handleClick(section) {
    if (actualPathname === "/") {
      pageScroll(section, true)
    } else {
      actionLoader(true)
      saveSection(section)
      setTimeout(() => {
        navigate(`/`)
      }, 1000)
    }
  }

  return (
    <div className="Horizontal">
      <ul>
        <li className="nav_std" onClick={() => handleClick("compagny")}>
          Notre Entreprise
        </li>
        <li className="nav_std" onClick={() => handleClick("services")}>
          Nos Services
        </li>
        <li className="nav_std" onClick={() => handleClick("contact")}>
          Nous Contacter
        </li>
      </ul>
    </div>
  )
}

export default Horizontal
