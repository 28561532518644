import React from "react"

import "./Footer.scss"

import LogoFooter from "@images/logos/logo_footer.png"

function Footer() {
  return (
    <div className="Footer">
      <div className="container">
        <div className="Footer-side Footer-side-left">
          <img
            className="Footer-side-logo"
            src={LogoFooter}
            alt="Les Charmes du Paysage"
          />
          <div className="Footer-side-legal">
            <div className="edito">
              <p className="bold">Les charmes du paysage</p>
              <p>Conception, aménagement, entretien de parcs et jardins</p>
              <p>2020 - Tous droits reservés</p>
            </div>
          </div>
        </div>
        <div className="Footer-side Footer-side-right">
          <div className="edito">
            <p>
              Sarl Les Charmes du Paysage
              <br />
              ZA de la Glacière
              <br />
              1 impasse des Roseaux
              <br />
              74570 Evires
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
