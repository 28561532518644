import React, { useContext } from "react"
import { navigate } from "gatsby"

import { InteractionsContext } from "@contexts/interactions"

import { pageScroll } from "@utils/scroller"

function Expand() {
  const {
    menuState,
    toggleMenu,
    actionLoader,
    actualPathname,
    saveSection,
  } = useContext(InteractionsContext)

  function handleClick(section) {
    if (actualPathname === "/") {
      toggleMenu()
      setTimeout(() => {
        pageScroll(section, true)
      }, 250)
    } else {
      actionLoader(true)
      saveSection(section)
      setTimeout(() => {
        toggleMenu()
        navigate(`/`)
      }, 1000)
    }
  }

  return (
    <div className={`Expand ${menuState && "active"}`}>
      <div className="Expand-burger" onClick={() => toggleMenu()}>
        <div className="Expand-burger-line" />
        <div className="Expand-burger-line" />
        <div className="Expand-burger-line" />
      </div>
      <div className="Expand-nav">
        <ul>
          <li onClick={() => handleClick("compagny")}>Notre Entreprise</li>
          <li onClick={() => handleClick("services")}>Nos Services</li>
          <li onClick={() => handleClick("contact")}>Nous Contacter</li>
        </ul>
      </div>
    </div>
  )
}

export default Expand
