import React, { useContext } from "react"
import { navigate } from "gatsby"

import Horizontal from "@components/header/nav/Horizontal"
import Expand from "@components/header/nav/Expand"

import { InteractionsContext } from "@contexts/interactions"

import { pageScroll } from "@utils/scroller"

import "./Header.scss"

import LogoHeader from "@images/logos/logo_header.png"

function Header() {
  const { actionLoader, actualPathname, menuState } = useContext(
    InteractionsContext
  )

  function handleClick() {
    if (!menuState) {
      if (actualPathname === "/") {
        pageScroll(null, true)
      } else {
        actionLoader(true)
        setTimeout(() => {
          navigate(`/`)
        }, 1000)
      }
    }
  }

  return (
    <nav className="Header">
      <div className="container">
        <div className="Header-logo">
          <img
            src={LogoHeader}
            alt="Les Charmes du Paysage"
            onClick={() => handleClick()}
          />
        </div>
        <div className="Header-nav">
          <Horizontal />
          <Expand />
        </div>
      </div>
    </nav>
  )
}

export default Header
