/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { InteractionsProvider } from "@contexts/interactions"

import Loading from "@components/modal/loading/Loading"
import Cookies from "@components/modal/cookies/Cookies"
import Header from "@components/header/Header"
import Footer from "@components/footer/Footer"

import "@styles/global.scss"

const MainLayout = ({ children }) => {
  const allowCookies =
    typeof window !== `undefined`
      ? sessionStorage.getItem("allow_cookies")
      : null

  return (
    <InteractionsProvider>
        <Header />
        <Loading />
        <Cookies active={!allowCookies} />
        <main>{children}</main>
        <Footer />
    </InteractionsProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
