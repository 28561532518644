import React, { useState, useEffect, useContext } from "react"

import { InteractionsContext } from "@contexts/interactions"

import "./Loading.scss"

import LogoLoader from "@images/logos/logo_loader.png"

function Loading() {
  const [loaderClass, setLoaderClass] = useState("")

  const { loaderState } = useContext(InteractionsContext)

  useEffect(() => {
    if (loaderState) {
      setLoaderClass("")
    } else {
      setTimeout(() => {
        setLoaderClass("leave")
      }, 1000)

      setTimeout(() => {
        setLoaderClass("unactive")
      }, 1750)
    }
  }, [loaderState])


  // ClassName Render Fixe
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])
  const key = isClient ? `client` : `server`
  // ClassName Render Fixe

  return (
    <div className={`Loading ${loaderClass}`}  key={key}>
      <div className="Loading-back">
        <div className="Loading-center">
          <img src={LogoLoader} alt="Les Charmes du Paysages" />
        </div>
      </div>
    </div>
  )
}

export default Loading
