import React, {createContext, useState, useEffect} from 'react'
import {arrayOf, element, object, oneOfType, any} from 'prop-types'

const defaultInteractionsContext = {
  toggleMenu: () => {},
  menuState: '',
  toggleSlideshow: () => {},
  slideshowState: '',
  toggleMailResponse: () => {},
  mailResponseState: '',
  actionLoader: () => {},
  loaderState: '',
  savePathname: () => {},
  actualPathname: '',
  saveSection: () => {},
  actualSection: '',
  saveCookies: () => {},
  cookiesState: ''
}

const InteractionsContext = createContext(defaultInteractionsContext)

let bodyScrollTop = 0

InteractionsProvider.propTypes = {
  children : oneOfType([
    arrayOf(element),
    object,
    any
  ]).isRequired,
}

function InteractionsProvider({children}){

  
  const [menuState, setMenuState] = useState(false)
  const [slideshowState, setSlideshowState] = useState(false)
  const [mailResponseState, setMailResponseState] = useState({
    active: false,
    sended: false
  })
  const [loaderState, setLoaderState] = useState(true)
  const [scrollActive, setScrollActive] = useState(true)
  const [actualPathname, setActualPathname] = useState('')
  const [actualSection, setActualSection] = useState('')
  const [cookiesState, setCookiesState] = useState(true)

  useEffect(() => {
    if (
      mailResponseState.active ||
      slideshowState ||
      (menuState && (window.innerWidth < 650)))
    {
      setScrollActive(false)
    }else {
      setScrollActive(true)
    }
  }, [menuState, slideshowState, mailResponseState])

  useEffect(() => {
    if(!scrollActive){
      bodyScrollTop = window.scrollY
      document.body.style.top = `-${bodyScrollTop}px`
      document.body.style.width = '100%'
      document.body.style.position = 'fixed'
    }else{
      document.body.style.position = ''
      document.body.style.top = ''
      window.scroll(0, bodyScrollTop)
    }
  }, [scrollActive])

  function toggleMenu() {
    setMenuState(!menuState)
  }

  function toggleSlideshow() {
    setSlideshowState(!slideshowState)
  }

  function toggleMailResponse(sended = mailResponseState.sended) {
    setMailResponseState({
      sended: sended,
      active: !mailResponseState.active
    })
  }

  function actionLoader(state) {
    setLoaderState(state)
  }

  function savePathname(pathname) {
    setActualPathname(pathname)
  }

  function saveSection(section) {
    setActualSection(section)
  }

  function saveCookies(state) {
    setCookiesState(state)
  }
  
  return (
    <InteractionsContext.Provider
      value={{
        toggleMenu,
        menuState,
        toggleSlideshow,
        slideshowState,
        toggleMailResponse,
        mailResponseState,
        actionLoader,
        loaderState,
        savePathname,
        actualPathname,
        saveSection,
        actualSection,
        saveCookies,
        cookiesState
      }}
    >
      {children}
    </InteractionsContext.Provider>
  )
}

export {InteractionsProvider, InteractionsContext}