import React, { useEffect, useContext } from "react"
import { bool } from "prop-types"

import { InteractionsContext } from "@contexts/interactions"

import "./Cookies.scss"

import VisualCookies from "@images/visuals/cookies.jpg"

Cookies.propTypes = {
  active: bool.isRequired,
}

function Cookies({ active }) {

  const { saveCookies, cookiesState } = useContext(InteractionsContext)

  useEffect(() => {
    saveCookies(active)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  function handleClick() {
    saveCookies(false)
    sessionStorage.setItem("allow_cookies", true)
  }

  return (
    <div className={`Cookies ${cookiesState && `active`}`} onClick={() => handleClick()}>
      <div className="Cookies-image">
        <img src={VisualCookies} alt="Cookies" />
      </div>
      <p>
        <span>Ce site utilise des cookies. </span>En continuant vous acceptez
        l'utilisation des cookies pour améliorer votre expérience de navigation
      </p>
      <div className="Cookies-close" />
    </div>
  )
}

export default Cookies
